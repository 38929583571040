var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('aside',{staticClass:"z-20 flex-shrink-0 hidden w-64 bg-white dark:bg-gray-800 md:block h-full"},[_c('div',{staticClass:"text-gray-500 dark:text-gray-400"},[_vm._m(0),_c('ul',{staticClass:"mt-6"}),_vm._l((_vm.fetched_routes),function(fetched_route,index){return _c('ul',{key:index,staticClass:"mt-2"},[_c('router-link',{attrs:{"to":{name:fetched_route.name}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var route = ref.route;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{staticClass:"relative px-6 py-3"},[(isActive)?_c('span',{staticClass:"absolute inset-y-0 left-0 w-1 bg-havelock-blue rounded-tr-lg rounded-br-lg",attrs:{"aria-hidden":"true"}}):_vm._e(),_c('a',{class:['inline-flex items-center w-full text-sm font-semibold ' +
                  'transition-colors duration-150 hover:text-gray-800 ' +
                   'dark:hover:text-gray-200 dark:text-gray-100', isActive ? 'text-gray-800': 'text-gray-500'],attrs:{"href":href},on:{"click":navigate}},[(!fetched_route.icon)?_c('svg',{staticClass:"w-5 h-5",attrs:{"aria-hidden":"true","fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"}})]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(fetched_route.icon)}}),_c('span',{class:['ml-2 capitalize']},[_vm._v(" "+_vm._s(fetched_route.verboseName ? _vm.activeLanguage.store.routes[fetched_route.verboseName] : (fetched_route.name).replace(/([A-Z])/g, " $1"))+" ")])])])]}}],null,true)})],1)})],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"py-2.5  text-center flex flex-row justify-between border-b bg-havelock-blue"},[_c('div',{staticClass:"ml-6 text-lg font-semibold text-white dark:text-gray-200 flex flex-row justify-items-end"},[_c('p',[_vm._v("EAC RDE")]),_c('img',{staticClass:" w-7 ml-3",attrs:{"alt":"EAC Logo","src":require("../assets/img/eac_logo.png")}})])])}]

export { render, staticRenderFns }